import { ChevronDownBold } from '@pretto/picto'

import { useEffect, useRef, useState } from 'react'

import * as S from './RowFaq.styles'

export type RowFaqProps = {
  answersMaxLines?: number
  isColored?: boolean
  questions: { question: string; answer: string }[]
  suptitle?: string
  title: string
}

export const RowFaq: React.FC<RowFaqProps> = ({ answersMaxLines, questions, suptitle, title }) => {
  const [isExpanded, setIsExpanded] = useState<boolean[]>(questions.map(() => false))
  const [answersMaxHeights, setAnswersMaxHeights] = useState<number[]>([])
  const [answerClamp, setAnswerClamp] = useState<boolean[]>(questions.map(() => true))
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

  const hiddenContentRef = useRef<HTMLDivElement[]>([])

  useEffect(() => () => clearTimeout(timerRef.current), [])

  useEffect(() => {
    if (hiddenContentRef.current.length > 0) {
      setAnswersMaxHeights(hiddenContentRef.current.map(element => element.getBoundingClientRect().height))
    }
  }, [])

  return (
    <S.Bloc itemScope itemType="https://schema.org/FAQPage">
      <S.TitleBloc>
        {suptitle && <S.Surtitle>{suptitle}</S.Surtitle>}
        <S.Title>{title}</S.Title>
      </S.TitleBloc>

      <S.QuestionsBloc>
        {questions.map(({ question, answer }, index) => {
          const handleOnViewMoreClick = () => {
            setIsExpanded(prev => {
              prev[index] = !prev[index]
              return [...prev]
            })

            timerRef.current = setTimeout(() => {
              setAnswerClamp(prev => {
                prev[index] = !prev[index]
                return [...prev]
              })
            }, 600)
          }

          const handleHiddenContentRef = (node: HTMLDivElement) => {
            hiddenContentRef.current[index] = node
          }

          return (
            <S.Couple itemScope itemProp="mainEntity" itemType="https://schema.org/Question" key={question}>
              <S.Question itemProp="name">{question}</S.Question>

              <S.Answer itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                <S.Content
                  answerClamp={answerClamp[index]}
                  answersMaxLines={answersMaxLines}
                  isExpanded={isExpanded[index]}
                  maxHeight={answersMaxHeights[index]}
                  itemProp="text"
                >
                  {answer}
                </S.Content>
                <S.HiddenContent ref={handleHiddenContentRef}>{answer}</S.HiddenContent>
              </S.Answer>

              {answersMaxLines && (
                <S.ViewMore onClick={handleOnViewMoreClick}>
                  <S.Text>{isExpanded[index] ? 'Voir moins' : 'Voir plus'}</S.Text>
                  <S.Picto as={ChevronDownBold} isExpanded={isExpanded[index]}></S.Picto>
                </S.ViewMore>
              )}
            </S.Couple>
          )
        })}
      </S.QuestionsBloc>
    </S.Bloc>
  )
}
